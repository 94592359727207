<template>
    <div class="app-body taskPage">
        <div class="a-flex-rsbc a-ptb-6">
            <span class="a-fs-16 a-fw-700">任务审核列表</span>
            <div class="a-flex-rcc">
                <el-button icon="el-icon-upload2" class="s-btn-exp" @click="exportfile">导出</el-button>
            </div>
        </div>
        <el-card class="el-main">
            <le-search-form @reset="handlerRest" @search="handlerSearch" id="leSearch">
                <le-input-district-choose 
                    label="请选择省市" 
                    :province.sync="pageParam.params.provinceCode" 
                    :city.sync="pageParam.params.cityCode"
                    :area.sync="pageParam.params.districtCode"></le-input-district-choose>
                <le-date-range 
                    label='提交时间'
                    ref="dateRange"
                    :minDate.sync="pageParam.params.startTime" 
                    :maxDate.sync="pageParam.params.endTime" 
                    :defaultTime="['00:00:00','23:59:59']"
                    valueFormat="yyyy-MM-dd HH:mm:ss" />
                <le-select-local-search label="核算状态" v-model="pageParam.params.reviewStatus" :options="reviewStatusDic" />
                <le-select-local-search label="任务名称" v-model="pageParam.params.taskId" :clearable='false' :options="taskIdDic" />
                <le-input label="提交人" v-model="pageParam.params.mobile" />
                <le-input label="核算编号" v-model="pageParam.params.id" />
            </le-search-form>
            <le-pagview ref="taskListPage" @setData="setTableData" :pageParam="pageParam" :isFixed="true" :tableRef="$refs.taskList" :pageSizeDefault='10'>
                <el-table ref="taskList" :data="tableData" :highlight-current-row="true" v-sticky="{ top: 0, parent:'.el-card__body' }" style="width: 100%">
                    <el-table-column prop="id" label="核算编号" min-width="140">
                        <template slot-scope="{ row }">
                            <span>{{ row.id || '-' }}</span>
                        </template>
                    </el-table-column>
                    <el-table-column prop="taskName" label="提交任务名称" min-width="120">
                        <template slot-scope="{ row }">
                            <span>{{ row.taskName || '-' }}</span>
                        </template>
                    </el-table-column>
                    <el-table-column prop="cityName" label="任务提交位置城市" min-width="140">
                        <template slot-scope="{ row }">
                            <span>{{ row.cityName || '-' }}</span>
                        </template>
                    </el-table-column>
                    <el-table-column prop="districtName" label="任务提交位置区域" min-width="140">
                        <template slot-scope="{ row }">
                            <span>{{ row.districtName || '-' }}</span>
                        </template>
                    </el-table-column>
                    <el-table-column prop="mobile" label="提交人" min-width="120">
                        <template slot-scope="{ row }">
                            <span>{{ row.mobile || '-' }}</span>
                        </template>
                    </el-table-column>
                    <el-table-column prop="deviceType" label="提交时间" min-width="160">
                        <template slot-scope="{ row }">
                            <span>{{ row.recordTime || '-' }}</span>
                        </template>
                    </el-table-column>
                    <el-table-column prop="reviewMobile" label="核算人" min-width="120">
                        <template slot-scope="{ row }">
                            <span>{{ row.reviewMobile || '-' }}</span>
                        </template>
                    </el-table-column>
                    <el-table-column prop="reviewTime" label="核算时间" min-width="160">
                        <template slot-scope="{ row }">
                            <span>{{ row.reviewTime || '-' }}</span>
                        </template>
                    </el-table-column>
                    <el-table-column prop="reviewStatus" label="核算状态" min-width="120">
                        <template slot-scope="{ row }">
                            <span>{{ row.reviewStatus | initDic(reviewStatusDic) }}</span>
                        </template>
                    </el-table-column>
                    <el-table-column prop="pid" label="操作" width="100" fixed="right">
                        <template slot-scope="scope">
                            <el-tooltip class="item" effect="dark" content="详情" placement="top">
                                <img src="../../assets/icon/option-audit.png" class="a-wh-16" @click="handleToDetails(scope.row)" />
                            </el-tooltip>
                        </template>
                    </el-table-column>
                </el-table>
            </le-pagview>
        </el-card>
    </div>
</template>

<script>
    import util from '../../../src/utils/util'
    export default {
        data () {
            return {
                util: util,
                tableData: [],
                pageParam: {
                    url: this.$Config.apiUrl.taskReviewRecordPage,
                    method: "post",
                    params: {
                        provinceCode: '',
                        cityCode: '',
                        districtCode: '',
                        id: '',
                        mobile: '',
                        reviewStatus: '',
                        taskId: '1',
                        startTime: '',
                        endTime: ''
                    },
                    freshCtrl: 1,
                },
                reviewStatusDic: [],
                taskIdDic: [{
                    label: '小区信息登记',
                    value: '1'
                }]
            }
        },
        created () {
            this.$getDic('reviewStatus','select').then(res=>{ this.reviewStatusDic = res; })
        },
        activated () {
            this.pageParam.freshCtrl++;
        },
        methods:{
            //获取列表
            setTableData(data) {
                this.tableData = data
            },
            handlerRest() {
                this.pageParam.params = {
                    provinceCode: '',
                    cityCode: '',
                    districtCode: '',
                    id: '',
                    mobile: '',
                    reviewStatus: '',
                    taskId: '1',
                    startTime: '',
                    endTime: ''
                };
                this.handlerSearch()
            },
            handlerSearch() {
                this.$refs['taskListPage'].pageNum = 1
                this.pageParam.freshCtrl++;
            },
            handleToDetails (datas) {
                this.$router.push({
                    path: '/task/task-datas-audit',
                    query:{
                        id: datas?datas.id:'',
                    }
                })
            },
            exportfile () {
                if(!this.pageParam.params.startTime) {
                    this.$message.error('导出时请先选择提交时间')
                    return
                }
                if(this.$getDay.enumerateDaysBetweenDates(this.pageParam.params.startTime,this.pageParam.params.endTime).length > 92){
                    this.$message.error('最大导出范围3个月')
                    return
                }
                let startTime = this.pageParam.params.startTime.split(' ')[0]
                let endTime = this.pageParam.params.endTime.split(' ')[0]
                this.$exfile({
                    code: 20,
                    fileName: startTime + ' 至 ' + endTime + '小区信息登记',
                    startTime: this.pageParam.params.startTime,
                    endTime: this.pageParam.params.endTime,
                    params: this.pageParam.params
                })
            }
        }
    }
</script>

<style lang="scss" scoped>
.taskPage{
    /deep/ .s-search-label{
        width: 100px !important
    }
}
.s-btn-add{
    width: 105px;
}

/deep/ .el-table__body-wrapper::-webkit-scrollbar {
    /*width: 0;宽度为0隐藏*/
    width: 0 !important;
    height: 0 !important;
}
/deep/ .el-table__fixed, /deep/ .el-table__fixed-right{
    height: 100% !important;
}
</style>